<template>
  <UiContainer class="!px-0 lg:!px-7">
    <ul
      class="scrollbar-hidden flex gap-2 overflow-y-visible overflow-x-scroll px-4 pb-2 md:gap-5 lg:flex-wrap lg:px-0"
    >
      <li v-for="date in visibleDates" :key="date.toString()">
        <UiButton
          severity="primary"
          type="button"
          class="relative overflow-hidden text-left md:text-center"
          @click="changeActiveDate(date)"
        >
          <UiGradientBackground
            v-if="modelValue && !Array.isArray(modelValue) && isSameDay(date, modelValue)"
            severity="primary"
          />
          <div class="relative flex flex-col md:flex-row md:items-center md:gap-1">
            <UiDate :value="date" template="dd" class="text-base font-medium md:text-sm" />
            <UiDate
              :value="date"
              :template="isSameYear(date, new Date()) ? 'MMMM' : 'MMMM yyyy'"
              class="text-xs font-medium md:text-sm"
            />
            <UiDate
              :value="date"
              :template="isMdAndUp ? 'EEEE' : 'EEEEEE'"
              :class="isWeekend(date) ? 'text-text-primary' : 'text-text-tertiary'"
              class="mt-1 text-xs md:mt-0"
            />
          </div>
        </UiButton>
      </li>
      <li v-if="dates.length > visibleDates.length">
        <UiDatePickerButton
          :value="modelValue"
          :inactive="calendarDatesIsInactive"
          class="h-full text-xs md:text-sm"
          @reset="changeActiveDate(dates[0])"
          @click.stop="openCalendar"
        />
      </li>
    </ul>
    <UiPopover v-if="isMdAndUp" ref="popover">
      <div class="max-h-[430px] w-[290px] p-3">
        <UiDatePicker
          :model-value="modelValue"
          :available-dates="dates"
          range
          class="scrollbar-hidden max-h-[430px]"
          @update:model-value="changeActiveDate($event || dates[0])"
        />
      </div>
    </UiPopover>
    <UiDialog v-else v-model="showMobileDatePicker" swipe-close position="bottom">
      <header>
        <UiButton
          icon-transparent
          class="!absolute right-4 top-7 z-20"
          @click="showMobileDatePicker = false"
        >
          <UiIcon name="x-mark" class="size-5 text-text-main lg:text-light" />
        </UiButton>
      </header>
      <UiDatePicker
        class="scrollbar-hidden z-10 !h-[calc(100dvh_-_16px)] rounded-t-3xl !p-0 !px-5"
        :model-value="modelValue"
        range
        :available-dates="dates"
        need-confirm
        show-reset
        header-classes="mb-3 flex px-0 pt-7"
        @update:model-value="
          ($event) => {
            changeActiveDate($event || dates[0])
            showMobileDatePicker = false
          }
        "
      >
        <template #header>
          <div>
            <UiTitle severity="h5">календарь</UiTitle>
          </div>
        </template>
        <template #confirm-text>Показать мероприятия</template>
      </UiDatePicker>
    </UiDialog>
  </UiContainer>
</template>

<script lang="ts" setup>
import { isWeekend, isSameYear, isSameDay } from 'date-fns'
import { computed, ref, useTemplateRef } from 'vue'
import { useBreakpoint } from '@/6_shared/lib'
import {
  UiButton,
  UiContainer,
  UiDate,
  UiDatePicker,
  UiDatePickerButton,
  UiDialog,
  UiGradientBackground,
  UiIcon,
  UiPopover,
  UiTitle
} from '@/6_shared/ui'

type PropType = {
  modelValue?: Date | Date[]
  dates: Date[]
}
type EmitType = {
  (e: 'update:modelValue', date: Date | Date[]): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const {
  md: [isMdAndUp],
  lg: [isLgAndUp]
} = useBreakpoint()
const MAX_VISIBLE_DATES = computed(() => (isLgAndUp.value ? 4 : 3))
const visibleDates = computed(() =>
  props.dates.filter((_, index) => index < MAX_VISIBLE_DATES.value)
)
const calendarDatesIsInactive = computed(
  () =>
    !Array.isArray(props.modelValue) &&
    !!visibleDates.value.find(
      (item) =>
        props.modelValue && !Array.isArray(props.modelValue) && isSameDay(item, props.modelValue)
    )
)

const openCalendar = (e: MouseEvent) => {
  if (isMdAndUp.value) return showPopover(e)
  showMobileDatePicker.value = true
}

const popover = useTemplateRef<typeof UiPopover>('popover')
const showPopover = (e: MouseEvent) => {
  popover.value && popover.value.show(e)
}
const showMobileDatePicker = ref(false)
const changeActiveDate = (date: Date | Date[]) => {
  emit('update:modelValue', date)
}
</script>
